import React, { useEffect, useState } from "react";
import client from "../../client";
import "../../App.css";

function About() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "About_Me_Section"] {
          About_Me_Text,
            Image {
            asset-> {
              url
            }
          },
          Icons
        }`
      )
      .then((data) => {
        if (data.length > 0) {
          setData(data);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-darkBlue px-6 md:px-24 py-20"
      id="about"
    >
      <div className="max-w-5xl w-full">
        <div className="flex flex-col md:flex-row md:items-start">
          <div className="order-2 md:order-1 flex-1 p-6">
            <div>
              <p className="text-xl sm:text-4xl font-semibold text-green border-b-4 border-blue w-fit">
                About Me
              </p>
              <p className="text-gray mt-2 md:mt-5 text-sm sm:text-lg w-10/12 font-medium">
                {data.map((item) => item.About_Me_Text)}
              </p>
            </div>
            <div className="flex flex-wrap items-center justify-start gap-2.5 mt-4">
              {data[0]?.Icons.map((iconHtml, index) => (
                <div
                  key={index}
                  className="flex cursor-pointer justify-center items-center w-10 h-10 md:w-12 md:h-12 rounded-md text-green text-lg md:text-2xl dark-shadow transform transition-transform duration-300 hover:-translate-y-1"
                  dangerouslySetInnerHTML={{ __html: iconHtml }}
                />
              ))}
            </div>
          </div>
          <div className="order-1 md:order-2 self-center md:self-end h-40 w-40 md:h-60 md:w-60">
            <img
              src={data[0]?.Image?.asset?.url}
              alt="aboutMePicture"
              className="rounded-sm"
            />
          </div>
        </div>
        <div className="mt-10 md:mt-40 flex justify-end">
          <a
            className="inline-flex items-center justify-center p-3 pt-2 pb-2 bg-green text-white rounded text-xs font-bold hover:scale-125"
            href="/resume"
            target="_blank"
          >
            View Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;

// import React, { useEffect, useState } from "react";
// import client from "../../client";
// import useGlobalStore from '../../store';

// function About() {
//   const [error, setError] = useState(false);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     client
//       .fetch(
//         `*[_type == "Home_Section"] {
//             Nav_Title,
//             Name,
//             Code_Phrase,
//             First_Description,
//             Second_Description
//         }`
//       )
//       .then((data) => {
//         if (data.length > 0) {
//           setData(data);
//           useGlobalStore.getState().setNavTitle(data[0].Nav_Title);
//         } else {
//           setError(true);
//         }
//       })
//       .catch(() => {
//         setError(true);
//       });
//   }, []);

//   return (
//     <div
//       className="flex justify-center items-center bg-darkBlue min-h-screen"
//       id="home"
//     >
//       <div className="text-left mb-40 md:mb-0 p-6 md:p-0">
//         <p className="text-green text-xl font-bold mb-3 md:mb-6 md:text-2xl">
//           Hi, my name is...
//         </p>

//         <p className="text-blue text-5xl mb-6 font-bold md:text-8xl">
//           {data.map((item) => item.Name)}
//         </p>

//         <p className="text-gray text-md md:text-3xl mb-6 font-bold font-mono">
//           <span className="text-green">{"<"}</span>{data.map((item) => item.Code_Phrase)}
//           <span className="text-green">{"/>"}</span>
//         </p>

//         <p className="text-gray text-sm md:text-xl font-semibold">
//           {data.map((item) => item.First_Description)}
//           <br />
//           {data.map((item) => item.Second_Description)}
//         </p>
//         <a
//           className="inline-flex w-auto items-center justify-center p-3 pt-2 pb-2 mt-10 bg-green text-white rounded-full text-xs font-bold hover:scale-125"
//           href="#about"
//         >
//           Scroll Down
//           <i className="bx bxs-chevron-down-circle bg-green pl-1"></i>
//         </a>
//       </div>
//     </div>
//   );
// }

// export default About;
